import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Input, Button, Tabs, Spin, message, InputNumber, Select, TimePicker, DatePicker } from 'antd';
import { GetAPI, PostAPI } from '../../../../../redux';
// import KdHsCode from "../../../../../assets/hscode.json"
import moment from 'moment';
import * as XLSX from 'xlsx';

const { Option } = Select;
const { TabPane } = Tabs;

function reorderData(header, data, indexesToMove) {
    const movedHeaders = indexesToMove.map(index => header[index]);
    const remainingHeaders = header.filter((_, i) => !indexesToMove.includes(i));
    const newHeader = [...movedHeaders, ...remainingHeaders];
    const indexMap = newHeader.map(h => header.indexOf(h));
    const newData = data.map(row => indexMap.map(i => row[i]));
    return { H: newHeader, D: newData };
}

const EditableTable = ({ headers, data, onDataChange }) => {
    const [dataSource, setDataSource] = useState([]);
    const { hscodeCache, consigneeCache } = useSelector(state => state.apps)

    useEffect(() => {
        if (data.length > 0) {
            setDataSource(
                data.map((item, index) => ({
                    key: index,
                    ...headers?.reduce((acc, header, i) => ({
                        ...acc,
                        [header]: item?.[i] ?? ''
                    }), {})
                }))
            );
        }
    }, [data, headers]);

    const handleInputChange = (value, recordKey, column) => {
        const newData = [...dataSource];
        const index = newData.findIndex(item => item.key === recordKey);
        if (index > -1) {
            newData[index][column] = value;
            setDataSource(newData);
            onDataChange(newData.map(item => headers.map(header => item[header])));
        }
    };

    const columns = headers.map(header => ({
        title: header,
        dataIndex: header,
        key: header,
        render: (text, record) => {
            const isHscode = header === "HS CODE";
            const isCsn = header === "NAMA CONSIGNEE";
            const isShp = header === "NAMA SHIPPER";
            const isTime = header === "JAM TIBA";
            const isDate = header === "TGL TIBA" || header === "WAKTU REKAM" || header === "WAKTU UPDATE" || header === "TGL HOST BLAWB" || header === "TGL MASTER BLAWB";

            const isNumber = typeof record[header] === 'number';
            return isNumber ? (
                <InputNumber
                    style={{ width: 200 }}
                    value={text}
                    onChange={value => handleInputChange(value ?? 0, record.key, header)}
                    parser={value => (value ? Number(value) : 0)}
                    onBlur={e => {
                        const newValue = e.target.value ? Number(e.target.value) : 0;
                        handleInputChange(newValue, record.key, header);
                    }}
                />
            ) : isHscode ?
                (
                    <Select
                        showSearch
                        placeholder="Cari HSCODE"
                        optionFilterProp="label"
                        value={text ?? ''}
                        style={{ width: 500 }}
                        onChange={value => handleInputChange(value, record.key, header)}
                        filterOption={(input, option) =>
                            option.label.toLowerCase().includes(input.toLowerCase())
                        }
                    >
                        {hscodeCache.map((item) => (
                            <Option key={item.hs_code} value={item.hs_code} label={`${item.hs_code} - ${item.hs_code_description}`}>
                                {item.hs_code} - {item.hs_code_description}
                            </Option>
                        ))}
                    </Select>
                ) : isCsn ?
                    (
                        <Select
                            showSearch
                            placeholder="Consignee"
                            optionFilterProp="label"
                            value={text ?? ''}
                            style={{ width: 300 }}
                            onChange={value => {
                                handleInputChange(value, record.key, header)
                                const result = consigneeCache.find(item => item.name === value);
                                handleInputChange(result?.npwp, record.key, "NPWP CONSIGNEE")
                                if (result?.address) {
                                    handleInputChange(result?.address, record.key, "ALMT CONSIGNEE")
                                }
                            }}
                            filterOption={(input, option) =>
                                option.label.toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {consigneeCache.map((item) => (
                                <Option key={item.name} value={item.name} label={`${item.name}`}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    )
                    : isShp ?
                        (
                            <Select
                                showSearch
                                placeholder="Shipper"
                                optionFilterProp="label"
                                value={text ?? ''}
                                style={{ width: 300 }}
                                onChange={value => {
                                    handleInputChange(value, record.key, header)
                                    const result = consigneeCache.find(item => item.name === value);
                                    handleInputChange(result?.npwp, record.key, "NPWP SHIPPER")
                                    if (result?.address) {
                                        handleInputChange(result?.address, record.key, "ALMT CONSIGNEE")
                                    }
                                }}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {consigneeCache.map((item) => (
                                    <Option key={item.name} value={item.name} label={`${item.name}`}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        )
                        : isTime ?
                            (
                                <TimePicker
                                    value={text ? moment(text, "HH:mm") : null}
                                    format="HH:mm"
                                    onChange={e => {
                                        const value = e.format("HH:mm");
                                        handleInputChange(value, record.key, header)
                                    }}
                                    allowClear={false}
                                />
                            ) : isDate ?
                                (
                                    <DatePicker
                                        value={text ? moment(text, "DD-MM-YYYY") : null}
                                        format="DD-MM-YYYY"
                                        onChange={e => {
                                            const value = e.format("DD-MM-YYYY");
                                            handleInputChange(value, record.key, header)
                                        }}
                                        style={{ width: 200 }}
                                        allowClear={false}
                                    />
                                ) :
                                (
                                    <Input
                                        style={{ width: 200 }}
                                        value={text ?? ''}
                                        onChange={e => handleInputChange(e.target.value, record.key, header)}
                                    />
                                );
        }
    }));


    return <Table size='small' dataSource={dataSource} columns={columns} pagination={false} bordered
        scroll={{
            x: 'max-content',
            y: 55 * 5,
        }}
    />;
};

export default ({ id }) => {
    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState(moment().unix());
    const [loading, setLoading] = useState(true);
    const [filename, setFileName] = useState(true);

    const [tabsData, setTabsData] = useState({
        Header: { H: [], D: [] },
        Master: { H: [], D: [] },
        Detil: { H: [], D: [] },
        Barang: { H: [], D: [] },
        Dokumen: { H: [], D: [] },
        Kontainer: { H: [], D: [] },
        Respon: { H: [], D: [] },
    });

    const handleSaveAll = () => {
        const obj = {
            _id: id,
            dHeader: tabsData.Header.D,
            dMaster: tabsData.Master.D,
            dDetail: tabsData.Detil.D,
            dBarang: tabsData.Barang.D,
            dDokumen: tabsData.Dokumen.D,
            dKontainer: tabsData.Kontainer.D,
            dRespon: tabsData.Respon.D
        };

        dispatch(PostAPI({ url: 'rskps/update', data: obj }))
            .then(() => {
                message.success('Data berhasil diperbarui!');
                setRefresh(moment().unix());
            });
    };

    const handleDownloadExcel = async () => {
        const workbook = XLSX.utils.book_new();
        Object.entries(tabsData).forEach(([tabName, { H, D }]) => {
            const worksheet = XLSX.utils.aoa_to_sheet([H, ...D]);
            const nameTab = tabName === "Master" ? "Master Entry" : tabName === "Detail" ? "Detil" : tabName === "Respon" ? "Respon Header" : tabName;
            XLSX.utils.book_append_sheet(workbook, worksheet, nameTab);
        });

        const excelBuffer = XLSX.write(workbook, { bookType: 'biff8', type: 'buffer' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.ms-excel' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${filename}.xls`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const getData = async () => {
        setLoading(true);
        try {
            const resp = await dispatch(GetAPI({ url: `rskps/detail/${id}` }));
            if (resp?.payload?.data) {
                const {
                    hHeader, dHeader, hMaster, dMaster,
                    hDetil, dDetail, hBarang, dBarang,
                    hDokumen, dDokumen, hKontainer, dKontainer,
                    hRespon, dRespon, flname
                } = resp.payload.data;

                setTabsData({
                    Header: { H: hHeader, D: dHeader },
                    Master: { H: hMaster, D: dMaster },
                    Detil: { H: hDetil, D: dDetail },
                    Barang: { H: hBarang, D: dBarang },
                    Dokumen: { H: hDokumen, D: dDokumen },
                    Kontainer: { H: hKontainer, D: dKontainer },
                    Respon: { H: hRespon, D: dRespon },
                });
                setFileName(flname)
            }
        } catch (error) {
            console.error("Gagal memuat data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getData();
    }, [refresh]);


    // tabsData.Header = reorderData(tabsData.Header.H, tabsData.Header.D, [23, 26]);
    // console.log(tabsData.Header);

    return (
        <div>
            <Tabs defaultActiveKey="1">
                {Object.entries(tabsData).map(([tabName, { H, D }], index) => (
                    <TabPane tab={tabName} key={index + 1}>
                        {loading ? (
                            <Spin tip="Memuat data..." />
                        ) : (
                            H.length > 0 && (
                                <EditableTable headers={H} data={D} onDataChange={newData => setTabsData(prev => ({ ...prev, [tabName]: { H, D: newData } }))} />
                            )
                        )}
                    </TabPane>
                ))}
            </Tabs>
            <Button type="primary" onClick={handleSaveAll} style={{ marginTop: 16 }} disabled={loading}>
                Simpan
            </Button>
            <Button type="default" onClick={handleDownloadExcel} style={{ marginTop: 16, marginLeft: 8 }} disabled={loading}>
                Download ke Excel .xls
            </Button>
        </div>
    );
};
