import { Form, Input } from 'antd';
import React, { useState } from 'react';
import FormPage from '../../../../../components/pages/FormPage';

const DefModel = {
    hs_code: '',
    hs_code_description: ''
}

export default () => {
    const [model, setModel] = useState(DefModel);
    return (
        <FormPage
            url={'api/v1/hs_code'}
            formValid={false}
            model={model}
            setModel={setModel}
            wrapperCol={{ span: 8 }}
            labelCol={{ span: 3 }}
            title={"hscode"}
            emptyModel={DefModel}
            callbackPath={"/master/hscode"}
        >
            <Form.Item name={'hs_code'} label="Code" rules={[{ required: true }]} >
                <Input autoComplete="off" onInput={e => e.target.value = e.target.value} autoFocus />
            </Form.Item>
            <Form.Item name={'hs_code_description'} label="Description" rules={[{ required: true }]} >
                <Input autoComplete="off" onInput={e => e.target.value = e.target.value} autoFocus />
            </Form.Item>
        </FormPage>
    )
}