import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GetAPI } from './apiHandling'
const Apps = createSlice({
    name: 'apps',
    initialState: { errorMessage: '', loading: false, airportCache: [], airlineCache: [], consigneeCache: [], hscodeCache: [] },
    reducers: {
        setLoading(state) {
            state.loading = true
        },
        unsetLoading(state) {
            state.loading = false
        },
        setAirport(state, payload) {
            state.airportCache = payload.payload
        },
        setAirline(state, payload) {
            state.airlineCache = payload.payload
        },
        setConsignee(state, payload) {
            state.consigneeCache = payload.payload
        },
        setHsCode(state, payload) {
            state.hscodeCache = payload.payload
        },
        setConfig(state, payload) {
            state.configCache = payload.payload
        },
        setVersion(state, payload) {
            state.versionCache = payload.payload
        },
        setVersionLast(state, payload) {
            state.versionLastCache = payload.payload
        }
    }
})

export const { setLoading, unsetLoading, setAirline, setAirport, setConsignee, setHsCode, setConfig, setVersion, setVersionLast } = Apps.actions

export const loadVersion = createAsyncThunk(
    'apps/load-version', async (payload, thunkApi) => {
        const { dispatch } = thunkApi
        const resp = await dispatch(GetAPI({ url: 'version' }))
        if (resp?.payload?.data) dispatch(setVersion(resp.payload.data))
    }
)

export const loadVersionLast = createAsyncThunk(
    'apps/load-version', async (payload, thunkApi) => {
        const { dispatch } = thunkApi
        const resp = await dispatch(GetAPI({ url: 'last_version' }))
        if (resp?.payload?.data) dispatch(setVersionLast(resp.payload.data))
    }
)

export const loadAirports = createAsyncThunk(
    'apps/load-airport', async (payload, thunkApi) => {
        const { dispatch } = thunkApi
        const resp = await dispatch(GetAPI({ url: 'api/v1/airport/all' }))
        if (resp?.payload?.data) dispatch(setAirport(resp.payload.data))
    }
)

export const loadAirlines = createAsyncThunk(
    'apps/load-airlines', async (payload, thunkApi) => {
        const { dispatch } = thunkApi
        const resp = await dispatch(GetAPI({ url: 'api/v1/airlines/all' }))
        if (resp?.payload?.data) dispatch(setAirline(resp.payload.data))
    }
)

export const loadConsignee = createAsyncThunk(
    'apps/load-consignee', async (payload, thunkApi) => {
        const { dispatch } = thunkApi
        const resp = await dispatch(GetAPI({ url: 'api/v1/consignee/all' }))
        if (resp?.payload?.data) dispatch(setConsignee(resp.payload.data))
    }
)

export const loadCofigs = createAsyncThunk(
    'apps/load-configs', async (payload, thunkApi) => {
        const { dispatch } = thunkApi
        const resp = await dispatch(GetAPI({ url: 'api/v1/config/all' }))
        if (resp?.payload?.data) dispatch(setConfig(resp.payload.data))
    }
)

export const loadHsCode = createAsyncThunk(
    'apps/load-hscode', async (payload, thunkApi) => {
        const { dispatch } = thunkApi
        const resp = await dispatch(GetAPI({ url: 'api/v1/hs_code/all' }))
        if (resp?.payload?.data) dispatch(setHsCode(resp.payload.data))
    }
)

export default Apps.reducer