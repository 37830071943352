import React from 'react';
import TablePage from '../../../../../components/pages/TablePage';
import moment from 'moment';
export default () => {
    const columns = [
        {
            selector: (row) => row?.hs_code,
            name: "Code",
            id: 'hs_code',
            width: "100px"
        },
        {
            selector: (row) => row?.hs_code_description,
            name: "Description",
            id: 'hs_code_description'
        }
    ]
    return (
        <TablePage
            title={"Master HS Code"}
            url="api/v1/hs_code"
            actionPosition={'first'}
            createPath="/master/hscode/create"
            editPath={"/master/hscode/edit"}
            columns={columns}
        />
    )
}